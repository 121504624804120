import Button from '@swe/shared/ui-kit/components/button';
import Modal from '@swe/shared/ui-kit/components/modal';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';

type RestoreCartModalProps = {
  visible: boolean;
  onMerge: () => any;
  onClear: () => any;
  onCancel: () => any;
};
const RestoreCartModal = ({ visible, onMerge, onClear, onCancel }: RestoreCartModalProps) => {
  return (
    <Modal
      visible={visible}
      ariaLabel="Restore cart"
      heading={
        <Text
          variant="headline"
          size="md"
        >
          Restore cart
        </Text>
      }
    >
      <Stack spacing="md">
        <Stack spacing="sm">
          <Text size="lg">
            Before adding items from your abandoned cart, would you like to clear your current cart?
          </Text>
          <Text size="lg">Please keep in mind that we can only restore products available on shelves right now</Text>
        </Stack>
        <Stack spacing="sm">
          <Button
            name="cart-merge-products-and-restore"
            onClick={onMerge}
          >
            Merge products from both
          </Button>
          <Button
            name="cart-clear-current-and-restore"
            onClick={onClear}
          >
            Clear current cart
          </Button>
          <Button
            name="cart-cancel-restoration"
            color="light"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export { RestoreCartModal };
