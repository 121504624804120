import { Hero } from '@swe/shared/ui-kit/components/hero';

import { CartIllustration } from '@swe/shared/ui-kit/components/illustration';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';

type UnableToRestoreProps = ComponentHasClassName;

const UnableToRestore = ({ className }: UnableToRestoreProps) => {
  const { toCatalog } = useCatalogQuery();
  return (
    <Hero
      className={cx(className, styles.root)}
      illustration={<CartIllustration />}
      title="We were unable to restore your cart 😥"
      content={
        <>
          <span>
            We apologize for the inconvenience. Unfortunately, all the items you had in your abandoned cart are
            currently unavailable or out of stock.
          </span>
          <span>
            Please continue browsing our website for other exciting products. If you have any questions, feel free to
            contact our customer support
          </span>
        </>
      }
      actions={[
        {
          name: 'cart-restoration-shop-available',
          children: 'Shop Now',
          color: 'primary',
          size: 'lg',
          onClick: () => toCatalog({ filters: null, subPath: null, searchTerm: null }),
        },
      ]}
    />
  );
};

export { UnableToRestore };
