import styles from './styles.module.scss';

import { PromoCard } from 'common/containers/promo-card';
import { CartPromoRecommendations } from 'entities/common/orders';

type PromoRecommendationProps = {
  recommendations: CartPromoRecommendations[];
};

const PromoRecommendation = ({ recommendations }: PromoRecommendationProps) => (
  <div className={styles.root}>
    {recommendations
      .filter(({ discountAmount, discountPercent }) => discountAmount || discountPercent)
      .map((recommendation, index) => (
        <PromoCard
          key={`${recommendation.id}_${index}`}
          recommendation={recommendation}
        />
      ))}
  </div>
);

export { PromoRecommendation };
