import { Hero } from '@swe/shared/ui-kit/components/hero';
import { CartIllustration } from '@swe/shared/ui-kit/components/illustration';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import cl from 'clsx';

import styles from './styles.module.scss';

type EmptyCartProps = {
  goToCatalog?(): void;
} & ComponentHasClassName;

const EmptyCart = ({ className, goToCatalog }: EmptyCartProps) => {
  return (
    <Hero
      className={cl(className, styles.root)}
      illustration={<CartIllustration />}
      title="Your Cart is Empty"
      content={<span>Time to add some products</span>}
      actions={[{ name: 'shop-now', children: 'Shop Now', onClick: goToCatalog }]}
    />
  );
};

export { EmptyCart };
export default EmptyCart;
