import { useFeatureFlagEnabled, useFeatureFlagVariantKey, useFeatureFlagPayload } from 'posthog-js/react';

import { ReactElement } from 'react';

import { ABTestEnumerable, GetDeclaration } from 'entities/analytics/ab-test';

type ABTestProps<T extends ABTestEnumerable> = {
  testName: T;
  children: (props: GetDeclaration<T>) => ReactElement | null;
  fallback?: ReactElement | null;
};

const useABTest = <T extends ABTestEnumerable>(testName: T): GetDeclaration<T> => {
  const enabled = useFeatureFlagEnabled(testName) as boolean;
  const group = useFeatureFlagVariantKey(testName) as GetDeclaration<T>['group'];
  const payload = useFeatureFlagPayload(testName) as GetDeclaration<T>['payload'];

  return { testName, enabled, group, payload };
};

const ABTest = <T extends ABTestEnumerable>({ testName, fallback = null, children }: ABTestProps<T>) => {
  const abTest = useABTest(testName);

  if (!abTest?.enabled) return fallback;

  return children(abTest);
};

export type { ABTestProps };
export { ABTest, useABTest };
