import { useBreakpoint } from '@swe/shared/tools/media';
import { Badge } from '@swe/shared/ui-kit/components/badge';
import Button from '@swe/shared/ui-kit/components/button';
import { InfoIcon, CheckCircleIcon, DiscountLabelIcon } from '@swe/shared/ui-kit/components/icon';
import { Modal } from '@swe/shared/ui-kit/components/modal';
import { Price } from '@swe/shared/ui-kit/components/price';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import { Text } from '@swe/shared/ui-kit/components/text';

import { Tooltip } from '@swe/shared/ui-kit/components/tooltip';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cn from 'clsx';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

import { promoMsg } from '@swe/shop-ui/common/utils/promo-recommendations';
import { useCartQuantity } from 'common/providers/cart';
import { useRouterNavigate } from 'common/router';
import { buildNamedId } from 'common/router/utils';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { DEFAULT_CATALOG_SORTING_METHOD } from 'domains/catalog/constants';
import { useCatalogQuery } from 'domains/catalog/use-cases/use-catalog-query';
import { CarouselProductsSkeletonized } from 'domains/shop/components/carousel-products';
import { GetAlsoBoughtWithEndpoint } from 'endpoints/product/get-also-bought-with-list';
import { GetListEndpoint } from 'endpoints/product/get-list';
import { CompilationType } from 'entities/common/compilation';

type FooterProps = {
  hasChanges: boolean;
  onCancel: () => void;
  total: number;
  savings: number;
  pending: boolean;
} & ComponentHasClassName;

const Footer = ({ hasChanges, onCancel, total, savings, className, pending }: FooterProps) => {
  const props = useMemo(
    () => ({
      ariaLabel: hasChanges ? 'Review cart' : 'No, thanks',
      onClick: onCancel,
      children: hasChanges ? (
        <Stack
          direction="column"
          spacing="none"
          className={styles.center}
        >
          <Text
            size="xxs"
            variant="headline"
          >
            Review Cart: <Price value={total} />
          </Text>
          <Text size="md">
            Total savings: <Price value={savings} />
          </Text>
        </Stack>
      ) : (
        'No, thanks'
      ),
    }),
    [hasChanges, onCancel, savings, total],
  );

  return (
    <Stack className={className}>
      <Button
        pending={pending}
        size="lg"
        color={hasChanges ? 'primary' : 'light'}
        {...props}
      />
    </Stack>
  );
};

type UltimatePromoProps = {
  visible: boolean;
  filter?: Record<string, any>;
  onClose: () => void;
  isApplied?: boolean;
  discountAmount?: number | null;
  discountPercent?: number | null;
  cartPending: boolean;
  variantIds?: VariantId[];
  name?: string;
  id?: number;
  total: number;
  minCartAmountNeeded?: number;
  savings: number;
};

const UltimatePromo = ({
  visible,
  filter,
  onClose,
  isApplied,
  discountAmount = 0,
  discountPercent = 0,
  minCartAmountNeeded = 0,
  cartPending,
  variantIds,
  name,
  id,
  total,
  savings,
}: UltimatePromoProps) => {
  const badgeText = promoMsg(!!isApplied, discountAmount, discountPercent, minCartAmountNeeded);

  const quantity = useCartQuantity();
  const prevQuantity = useRef<number | null>(null);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (visible) {
      if (prevQuantity.current === null) {
        prevQuantity.current = quantity;
      } else if (prevQuantity.current !== quantity) {
        setHasChanges(true);
      }
    } else {
      prevQuantity.current = null;
      setHasChanges(false);
    }
  }, [quantity, visible]);

  const { saleType } = useSaleType();
  const { platformOs } = usePlatformOs();
  const { data } = GetListEndpoint.useRequest(
    visible
      ? {
          filters: filter,
          page: 1,
          pageSize: 10,
          sortingMethodId: DEFAULT_CATALOG_SORTING_METHOD,
          saleType,
          isInStockOnly: true,
          platformOs,
        }
      : null,
    {},
  );

  const { data: alsoBoughtWithList } = GetAlsoBoughtWithEndpoint.useRequest(
    variantIds && variantIds?.length > 0
      ? {
          saleType,
          variantIds,
          productIds: [],
          platformOs,
        }
      : null,
  );
  const { desktop } = useBreakpoint();

  const navigate = useRouterNavigate();
  const { buildCatalogLink } = useCatalogQuery();

  const onViewAll = useCallback(() => {
    onClose();
    if (!id) {
      return;
    }
    const link = buildCatalogLink({
      subPath: [CompilationType.DISCOUNT, buildNamedId(name, id)],
      filters: null,
      searchTerm: null,
      page: null,
    });
    void navigate(link);
  }, [id, buildCatalogLink, name, navigate, onClose]);

  return (
    <Modal
      visible={visible}
      ariaLabel="Ultimate promo"
      size="xxl"
      heading={
        <>
          <span>Special Deal for You</span>
          <Stack
            spacing="xxs"
            direction="row"
            mt="md"
          >
            <Badge
              icon={isApplied ? CheckCircleIcon : DiscountLabelIcon}
              color={isApplied ? 'success' : 'warning'}
              size="md"
            >
              {badgeText}
            </Badge>
            <Tooltip content="Other promos may apply">
              <Button
                color="light"
                icon={InfoIcon}
                ariaLabel="Info"
                size="xs"
              />
            </Tooltip>
          </Stack>
        </>
      }
      onClose={onClose}
      showCloseButton
    >
      <Stack direction="column">
        <CarouselProductsSkeletonized
          name="Popular"
          onViewAll={onViewAll}
          products={data?.list}
          id="special_deals"
          analyticalItemListName="cart_promo"
        />
        {variantIds && variantIds?.length > 0 ? (
          <CarouselProductsSkeletonized
            name="You may also be interested in"
            total={alsoBoughtWithList?.length}
            products={alsoBoughtWithList}
            id="frequently_bought_together"
          />
        ) : null}
        <Footer
          className={cn(styles.footer, desktop && styles._desk)}
          total={total}
          savings={savings}
          hasChanges={hasChanges}
          onCancel={onClose}
          pending={cartPending}
        />
      </Stack>
    </Modal>
  );
};

export { UltimatePromo };
