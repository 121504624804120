import { formatPrice } from '@swe/shared/utils/price';

import { PromoRecommendations, ActionApplicationMode, CartPromoRecommendations } from 'entities/common/orders';

const filterOutUltimatePromos = (promos: PromoRecommendations[]) =>
  promos.filter(({ applicationMode }) => applicationMode !== ActionApplicationMode.Ultimate);

const mergePromosList = (
  recommendations: PromoRecommendations[],
  appliedRecommendations: PromoRecommendations[],
): CartPromoRecommendations[] => {
  const result: CartPromoRecommendations[] = [
    ...filterOutUltimatePromos(recommendations).map((promo) => ({ ...promo, isApplied: false })),
  ];

  if (!result.length) {
    result.push(...filterOutUltimatePromos(appliedRecommendations).map((promo) => ({ ...promo, isApplied: true })));
  }

  return result;
};

const formatDiscount = (amount: number | null, percent: number | null) => {
  return amount ? `${formatPrice(amount)}` : `${Number(percent) * 100}%`;
};

const promoMsg = (
  isApplied: boolean,
  discountAmount: number | null,
  discountPercent: number | null,
  minCartAmountNeeded: number,
) =>
  isApplied
    ? `Discount ${formatDiscount(discountAmount, discountPercent)} applied`
    : `Add ${formatPrice(minCartAmountNeeded)} to get ${formatDiscount(discountAmount, discountPercent)}`;

export { mergePromosList, formatDiscount, promoMsg };
