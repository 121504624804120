import { PureButton } from '@swe/shared/ui-kit/components/pure-button';
import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { range } from '@swe/shared/utils/array';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { ProductCardSkeleton } from 'common/components/product-card/horizontal/skeleton';
import { ProductCardConnected } from 'common/containers/product-card';
import { CartViewItem } from 'common/providers/cart';
import { ProductSaleType } from 'entities/product/product';

type ListProps = {
  availableItems: CartViewItem[];
  unavailableItems: CartViewItem[];
  saleType?: ProductSaleType;
  isLoading?: boolean;
  onRemoveAll?(by: ProductSaleType): void;
} & ComponentHasClassName;

const List = ({ saleType, isLoading, onRemoveAll, unavailableItems, availableItems }: ListProps) => {
  const handleRemoveAll = useCallback(() => {
    onRemoveAll?.(saleType === ProductSaleType.MEDICAL ? ProductSaleType.RECREATIONAL : ProductSaleType.MEDICAL);
  }, [onRemoveAll, saleType]);

  if (isLoading) {
    return (
      <Stack>
        {range(3).map((_, idx) => (
          <ProductCardSkeleton key={idx} />
        ))}
      </Stack>
    );
  }

  return (
    <Stack spacing="lg">
      {unavailableItems.length > 0 && (
        <div>
          <Stack
            direction="row"
            spacing="lg"
            className={styles.header}
          >
            <SectionHeading size="md">Unavailable for Purchase</SectionHeading>
            <PureButton onClick={handleRemoveAll}>
              <Text
                variant="body"
                size="xl"
                className={styles.btnDelete}
              >
                Remove
              </Text>
            </PureButton>
          </Stack>
          <Stack>
            {unavailableItems?.map((item, index) => {
              return (
                <ProductCardConnected
                  key={index}
                  layout="horizontal"
                  product={item.product}
                  unavailable={item.unavailable}
                  getQuantity={() => item.qty}
                  index={index}
                  analyticalItemListId="cart"
                  analyticalItemListName="cart"
                />
              );
            })}
          </Stack>
        </div>
      )}
      {availableItems.length > 0 && (
        <div>
          {unavailableItems.length > 0 && <SectionHeading size="md">Available for Purchase</SectionHeading>}
          <Stack>
            {availableItems?.map((item, index) => {
              return (
                <ProductCardConnected
                  key={index}
                  layout="horizontal"
                  product={item.product}
                  unavailable={item.unavailable}
                  getQuantity={() => item.qty}
                  onRemoveAll={item.onRemoveAll}
                  index={index}
                  analyticalItemListId="cart"
                  analyticalItemListName="cart"
                />
              );
            })}
          </Stack>
        </div>
      )}
    </Stack>
  );
};

export type { ListProps };
export { List };
export default List;
