enum ABTestEnumerable {
  SpecialPromoInCart = 'ecom-1743-special-promo-in-cart',
}

type ABTestDeclaration<
  TestName extends ABTestEnumerable,
  Groups extends string,
  Payload extends Record<string, any> | undefined = undefined,
> = {
  testName: TestName;
  enabled: boolean;
  group: 'control' | Groups;
  payload: Payload extends undefined ? undefined : Payload;
};

type Declarations = {
  [ABTestEnumerable.SpecialPromoInCart]: ABTestDeclaration<ABTestEnumerable.SpecialPromoInCart, 'group1'>;
};

type GetDeclaration<TestName extends ABTestEnumerable> = Declarations[TestName];

export type { GetDeclaration };
export { ABTestEnumerable };
