import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';
import { useTheme } from '@swe/shared/ui-kit/theme/provider';
import { useId } from 'react';

const ProductCardSkeleton = () => {
  const theme = useTheme();
  const id = useId();

  return (
    <Skeleton
      uniqueKey={`product_card_skeleton_${id}`}
      height={170}
    >
      <Bone
        x="0"
        y="0"
        rx={theme.productCard.borderRadius}
        ry={theme.productCard.borderRadius}
        width="100%"
        height="100%"
      />
    </Skeleton>
  );
};

export { ProductCardSkeleton };
