import { Link } from '@swe/shared/providers/router/link';
import Badge from '@swe/shared/ui-kit/components/badge';
import { CheckIcon, ChevronRightIcon, PercentIcon } from '@swe/shared/ui-kit/components/icon';

import { SectionHeading } from '@swe/shared/ui-kit/components/section-heading';
import cn from 'clsx';

import React from 'react';

import styles from './styles.module.scss';

import { promoMsg } from '@swe/shop-ui/common/utils/promo-recommendations';
import { buildNamedId } from 'common/router/utils';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import { CompilationType } from 'entities/common/compilation';
import { CartPromoRecommendations } from 'entities/common/orders';

type PromoCardProps = {
  recommendation: CartPromoRecommendations;
};

const PromoCard = ({ recommendation }: PromoCardProps) => {
  const { buildCatalogLink } = useCatalogQuery();

  const { id, name, discountAmount, discountPercent, minCartAmountNeeded, isApplied } = recommendation;

  const shortActionText = promoMsg(isApplied, discountAmount, discountPercent, minCartAmountNeeded);

  return (
    <Link
      key={id}
      className={cn(styles.root, isApplied ? styles.applied : styles.active)}
      href={buildCatalogLink({
        subPath: [CompilationType.DISCOUNT, buildNamedId(name, id)],
        filters: null,
        searchTerm: null,
        page: null,
      })}
    >
      <SectionHeading
        trailIcon={isApplied ? undefined : ChevronRightIcon}
        size="sm"
      >
        <div className={styles.promoCardContent}>
          <Badge
            icon={isApplied ? CheckIcon : PercentIcon}
            className={styles.promoBadge}
            size="sm"
            color={isApplied ? 'success' : 'warning'}
          />
          {shortActionText}
        </div>
      </SectionHeading>
    </Link>
  );
};

export { PromoCard };
