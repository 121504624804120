import createModernEndpoint from '@swe/shared/network/endpoint-factories/modern';

type CaregiverPatients = {
  patients: CaregiverPatient[];
};

type CaregiverPatient = {
  id: number;
  name: string;
  medicalId?: string;
};

type Params = undefined;

const NAME = '/Cart/GetPatientsList';
const GetCaregiverPatientsList = createModernEndpoint<Params, CaregiverPatients>(NAME);

export type { CaregiverPatient, CaregiverPatients };
export default GetCaregiverPatientsList;
export { GetCaregiverPatientsList };
