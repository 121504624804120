import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { SnackbarService } from '@swe/shared/providers/snackbar';
import { omit } from '@swe/shared/utils/object';

import { useCallback, useEffect, useRef, useState } from 'react';

import { productAnalyticsMap } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useCart, useCartMeta, useCartState, useCartUtils } from 'common/providers/cart/context';
import { useRouterNavigate, useRouterQuery } from 'common/router';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { RestoreCartModal } from 'domains/cart/containers/restore-cart-modal';
import CartRestoreEndpoint, { RestorationStrategy } from 'endpoints/cart/restore';

type Command<Type, Payload> = {
  name: Type;
  data: Payload;
};

enum RestoreCommand {
  Name = 'fill.cart',
}
type RestoreCommandPayload = { id: number; qty: number }[];
type RestoreCartCommand = Command<RestoreCommand.Name, RestoreCommandPayload>;

const isValidRestoreCommand = (command: Command<any, any>): command is RestoreCartCommand =>
  command.name === RestoreCommand.Name && command.data?.length > 0;

const parseRestoreCommand = (query: Record<string, any>, onError?: () => void): RestoreCartCommand | undefined => {
  if (!query.cmd) return;

  try {
    const queryCmd = String(query.cmd);
    const command = JSON.parse(window.atob(queryCmd)) as Command<any, any>;
    if (!isValidRestoreCommand(command)) return;

    return command;
  } catch (e) {
    console.error(e);
    onError?.();
  }
};

const useCartRestoration = () => {
  const query = useRouterQuery();
  const navigate = useRouterNavigate();
  const { saleType } = useSaleType();
  const { mutate: mutateCart } = useCartUtils();
  const { isEmpty } = useCartMeta();
  const { isLoading } = useCartState();

  const isRestorationHandled = useRef(false);
  const [isRestorationInProgress, setRestorationInProgress] = useState(true);
  const [isCartRestorationFailed, setCartRestorationFailed] = useState(false);
  const [isCartRestorationModalVisible, open, close] = useToggleable();
  const cart = useCart();
  const { pushEvent } = useAnalytics();

  const clearQuery = useCallback(
    () =>
      navigate(
        {
          query: omit({ ...query }, 'cmd'),
        },
        { replace: true },
      ),
    [query, navigate],
  );
  const { platformOs } = usePlatformOs();
  const restoreCart = useCallback(
    async (products: RestoreCommandPayload, restoreMode: RestorationStrategy) => {
      isRestorationHandled.current = true;
      try {
        const patientId = Array.isArray(query.patientId) ? null : query.patientId ? Number(query.patientId) : null;
        setRestorationInProgress(true);
        const variants = products.map(({ id, qty }) => ({
          qty,
          variantId: id,
        }));
        const restoredCart = await CartRestoreEndpoint.request({
          saleType,
          restoreMode,
          variants,
          platformOs,
          patientId,
        });
        await mutateCart(restoredCart, false);
        if (restoredCart.cartRestoredPartially) {
          if (restoredCart.items.length > 0) {
            SnackbarService.push({
              type: 'warning',
              message: "We've added all available items to your cart, but some items are currently out of stock.",
            });
          } else {
            setCartRestorationFailed(true);
          }
        }
        const analyticsItems = restoredCart.items.reduce<any[]>((acc, { product, variantId: _variantId }, index) => {
          const v = variants.find(({ variantId }) => `${variantId}` === `${_variantId}`);
          if (!v) {
            return acc;
          }
          return acc.concat(
            productAnalyticsMap({
              product,
              qtyInCart: v.qty,
              cart,
              index,
              analyticalItemListName: '',
              analyticalItemListId: '',
            }),
          );
        }, []);
        pushEvent(AEventType.ADD_TO_CART, analyticsItems);
      } catch (e) {
        console.info(e);
        isRestorationHandled.current = false;
      } finally {
        setRestorationInProgress(false);
        setTimeout(clearQuery, 0);
      }
    },
    [query.patientId, saleType, platformOs, clearQuery, mutateCart, pushEvent, cart],
  );
  const mergeAndRestore = useCallback(async () => {
    const command = parseRestoreCommand(query);
    if (command) {
      await restoreCart(command.data, RestorationStrategy.Merge);
      close();
    }
  }, [close, query, restoreCart]);
  const clearAndRestore = useCallback(async () => {
    const command = parseRestoreCommand(query);
    if (command) {
      await restoreCart(command.data, RestorationStrategy.TakeAbandoned);
      close();
    }
  }, [close, query, restoreCart]);
  const cancelRestoration = useCallback(() => {
    void clearQuery();
    close();
  }, [clearQuery, close]);

  useEffect(() => {
    if (isLoading || isRestorationHandled.current) return;

    const command = parseRestoreCommand(query, clearQuery);
    if (!command) {
      setRestorationInProgress(false);
      return;
    }

    if (isEmpty) {
      void clearAndRestore();
    } else {
      open();
    }
  }, [clearAndRestore, clearQuery, isEmpty, isLoading, open, query]);

  return {
    isCartRestorationFailed,
    isRestorationInProgress,
    RestorationModal: (
      <RestoreCartModal
        visible={isCartRestorationModalVisible}
        onMerge={mergeAndRestore}
        onClear={clearAndRestore}
        onCancel={cancelRestoration}
      />
    ),
  };
};

export { useCartRestoration };
