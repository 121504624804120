import { SnackbarService } from '@swe/shared/providers/snackbar';
import { formatPrice } from '@swe/shared/utils/price';

import { useCallback, useMemo, useState } from 'react';

import { useCaregiverPatientsList } from './use-caregiver-patients-list';

import { usePickupDeliverySettings } from 'common/containers/header/containers/sub-header/use-cases/use-pickup-delivery-settings';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import {
  useCart as _useCart,
  useCartActions,
  useCartItemsWithAvailability,
  useCartMeta,
  useCartPrice,
  useCartState,
  useCartUtils,
} from 'common/providers/cart';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';

import { useMedicalPurchaseConfirmation } from 'common/use-cases/use-medical-purchase-confirmation';
import { usePlatformOs } from 'common/use-cases/use-platform-os';
import { useSaleType } from 'common/use-cases/use-sale-type';
import { SUBCODE_SHOW_AS_WARNING } from 'domains/cart/constants';
import { useCartRestoration } from 'domains/cart/use-cases/use-cart-restoration';
import { useFulfillmentType } from 'domains/checkout/use-cases/use-fulfullment-type';
import { CheckoutEndpoint } from 'endpoints/cart/checkout';

const useCart = () => {
  const { saleType } = useSaleType();
  const cart = _useCart();
  const { isLoading, isValidating, isLoadingUpdate, isLoadingAdd, isLoadingRemove } = useCartState();
  const { unavailableItems, availableItems } = useCartItemsWithAvailability();
  const { isOutOfLimit, isEmpty } = useCartMeta();
  const price = useCartPrice();
  const { addToCart, removeFromCart } = useCartActions();
  const { mutate: cartMutate } = useCartUtils();
  const { isCartRestorationFailed, isRestorationInProgress, RestorationModal } = useCartRestoration();

  const navigate = useRouterNavigate();
  const { redirectUnauthorized, user } = useCurrentUser();
  const { orderType, deliveryAddress, deliveryLocation, deliveryAddressSecondLine, isCaregiverOrdersEnabled } =
    usePickupDeliverySettings();

  const fullName = useMemo(
    () => `${user?.firstName?.trim() || ''} ${user?.lastName?.trim() || ''}`.trim(),
    [user?.firstName, user?.lastName],
  );

  const { getFulfillmentOptions } = useFulfillmentType();
  const { caregiverPatientsSelectOptions } = useCaregiverPatientsList({
    isCaregiverEnabled: user?.isCaregiver && isCaregiverOrdersEnabled,
    currentUser: {
      id: user?.accountId,
      name: fullName,
    },
  });

  const [pendingCheckout, setPendingCheckout] = useState(false);
  const { confirmMedicalPurchase } = useMedicalPurchaseConfirmation();

  const { pushEvent } = useAnalytics();

  const fulfillmentType = getFulfillmentOptions(orderType)[0]?.value;

  const { platformOs } = usePlatformOs();
  const proceedToCheckout = useCallback(async () => {
    if (
      await redirectUnauthorized(false, {
        to: 'checkout',
      })
    ) {
      return;
    }
    if (isOutOfLimit) {
      SnackbarService.push({
        type: 'warning',
        message: 'Out of limits!',
      });
    }
    if (fulfillmentType) {
      setPendingCheckout(true);
      try {
        const data = await CheckoutEndpoint.request(
          {
            saleType,
            fulfillmentType,
            deliveryAddress,
            deliveryLocation,
            secondaryAddress: deliveryAddressSecondLine,
            platformOs,
          },
          { notifyWithSnackbar: false },
        );

        pushEvent(AEventType.CART_CHECKOUT, undefined);

        await Promise.all([navigate(Routes.Checkout, { replace: true }), cartMutate(data)]);
      } catch (e) {
        if ((e as { code: number }).code === 401) {
          void navigate(Routes.SignIn);
          return;
        }
        if ((e as { subCode: number }).subCode === 547) {
          await confirmMedicalPurchase();
        } else {
          SnackbarService.push({
            type: SUBCODE_SHOW_AS_WARNING.includes((e as { subCode: number }).subCode) ? 'warning' : 'danger',
            message: (e as { message: string }).message,
          });
        }
      } finally {
        setPendingCheckout(false);
      }
    }
  }, [
    redirectUnauthorized,
    isOutOfLimit,
    fulfillmentType,
    saleType,
    deliveryAddress,
    deliveryLocation,
    deliveryAddressSecondLine,
    platformOs,
    pushEvent,
    navigate,
    cartMutate,
    confirmMedicalPurchase,
  ]);

  const variantIds = useMemo(() => {
    if (!cart?.items) {
      return [];
    }
    return cart.items.map((item) => item.product.variants[0].id);
  }, [cart?.items]);

  const quantity = useMemo(() => cart?.items?.reduce((acc, { qty }) => acc + qty, 0) || 0, [cart?.items]);

  const removeUnavailableItems = useCallback(() => {
    unavailableItems.forEach((item) => removeFromCart(item));
  }, [removeFromCart, unavailableItems]);

  const alertBock: { title?: string; content?: string } = {};

  if (cart && Number(cart.amountLeftForDelivery) > 0) {
    alertBock.title = 'Want to use delivery?';
    alertBock.content = `Add ${formatPrice(Number(cart.amountLeftForDelivery))} to get delivery`;
  } else if (cart && Number(cart.amountLeftForFreeDelivery) > 0) {
    alertBock.content = `Add ${formatPrice(Number(cart.amountLeftForFreeDelivery))} to get free delivery`;
  }

  return {
    RestorationModal,
    unavailableItems,
    availableItems,
    quantity,
    isOutOfLimit,
    price,
    isLoading: isLoading || isRestorationInProgress,
    isRestorationInProgress,
    isValidating,
    isCartRestorationFailed,
    addToCart,
    removeFromCart,
    removeUnavailableItems,
    proceedToCheckout,
    cart,
    alertBock,
    variantIds,
    isEmpty,
    isLoadingUpdate,
    pendingCheckout,
    caregiverPatientsSelectOptions,
    isCaregiver: !!user?.isCaregiver,
    user,
    isLoadingAdd,
    isLoadingRemove,
  };
};

export { useCart };
export default useCart;
