import { Badge } from '@swe/shared/ui-kit/components/badge';
import { Toggle } from '@swe/shared/ui-kit/components/form/toggle';

import { QuestionIcon } from '@swe/shared/ui-kit/components/icon';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import styles from './styles.module.scss';

import { getRegionAbbreviation } from 'common/entities/get-region-abbreviation';
import { useCartMeta, useCartResidency } from 'common/providers/cart';
import { useStoreConfig } from 'common/providers/config';
import GetShopInfo from 'endpoints/shop/get-shop-info';

const ResidenceToggle = () => {
  const { isEmpty } = useCartMeta();
  const { isResident, updateResident, isLoading } = useCartResidency();
  const { data } = GetShopInfo.useRequest();

  const regionName = data ? getRegionAbbreviation(data.location.region?.name) : '';

  const { isResidenceToggleEnabled } = useStoreConfig();

  if (!isResidenceToggleEnabled || !regionName || isEmpty) {
    return null;
  }

  return (
    <Stack
      direction="row"
      className={styles.root}
      spacing="xxs"
    >
      <Toggle
        name="isResident"
        label={`I'm ${regionName} Resident`}
        value={isResident}
        onChange={updateResident}
        disabled={isLoading}
      />
      <Badge
        tooltip={`Out of state customers have reduced purchase limits. If you aren't an ${regionName} resident, your order may be adjusted in-store.`}
        color="primary"
        size="md"
        icon={QuestionIcon}
      />
    </Stack>
  );
};

export { ResidenceToggle };
export default ResidenceToggle;
