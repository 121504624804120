import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { Cont404 } from 'common/containers/404';
import { useStoreConfig } from 'common/providers/config';
import { Cart } from 'domains/cart/containers/cart';
import { ResidenceToggle } from 'domains/cart/containers/residence-toggle';

const PAGE_TITLE = 'Cart';

const CartPage: ApplicationPage = () => {
  const { isOnlineOrderingDisabled } = useStoreConfig();

  if (isOnlineOrderingDisabled) {
    return <Cont404 />;
  }

  return (
    <Page
      header={{ title: PAGE_TITLE, info: <ResidenceToggle /> }}
      authorized={false}
    >
      <Cart />
    </Page>
  );
};

CartPage.getMeta = () => {
  return {
    title: PAGE_TITLE,
  };
};

export default CartPage;
