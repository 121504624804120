import { isMatchRouter } from '@swe/shared/providers/router/helpers';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { CartPrice } from 'common/providers/cart';
import { useCurrentUser } from 'common/providers/user';
import { useRouterHistory } from 'common/router';
import { Routes } from 'common/router/constants';
import { ULTIMATE_MODAL_KEY, ULTIMATE_MODAL_SHOW_FLAG } from 'domains/cart/containers/ultimate-promo/constants';
import { Cart } from 'entities/cart/cart';
import { ActionApplicationMode, PromoRecommendations } from 'entities/common/orders';

const useUltimatePromo = ({
  cart,
  cartPending,
  variantIds,
  price,
}: {
  cart?: Cart;
  cartPending: boolean;
  variantIds?: VariantId[];
  price: CartPrice;
}) => {
  const [initialUltimatePromoData, setInitialUltimatePromoData] = useState<PromoRecommendations | null>(null);

  const currentUltimatePromo = useMemo(() => {
    if (cart?.order?.upsellModalPromoRecommendations?.find(({ id }) => id === initialUltimatePromoData?.id)) {
      return initialUltimatePromoData;
    }
    return cart?.order?.upsellModalAppliedPromos?.[0];
  }, [cart?.order?.upsellModalAppliedPromos, cart?.order?.upsellModalPromoRecommendations, initialUltimatePromoData]);

  const isAppliedUltimatePromo = cart?.order?.upsellModalAppliedPromos?.[0]?.id === currentUltimatePromo?.id;

  const { isLoggedIn } = useCurrentUser();
  const ultimatePromo = useMemo(() => {
    return cart?.order?.upsellModalPromoRecommendations?.find(
      ({ applicationMode, displayInUpsellModal }) =>
        applicationMode === ActionApplicationMode.Ultimate && displayInUpsellModal,
    );
  }, [cart?.order?.upsellModalPromoRecommendations]);

  const checkPeriod = useCallback(() => {
    const lastCall = parseInt(localStorage.getItem(ULTIMATE_MODAL_KEY) || '0', 10);
    return (Date.now() - lastCall) / 1000 > 60 * 30;
  }, []);

  const openModal = useCallback((ultimatePromo: PromoRecommendations) => {
    setInitialUltimatePromoData(ultimatePromo);
    localStorage.setItem(ULTIMATE_MODAL_KEY, `${Date.now()}`);
  }, []);

  const { history } = useRouterHistory();
  useEffect(() => {
    const flag = localStorage.getItem(ULTIMATE_MODAL_SHOW_FLAG);
    const penultimateIdx = history.length - 1;
    const historyItem = history[penultimateIdx];

    if (
      !isLoggedIn ||
      !historyItem ||
      !historyItem.url.pathname ||
      !isMatchRouter(Routes.Cart, historyItem.url.pathname) ||
      !cart?.order
    ) {
      return;
    }

    if (ultimatePromo && flag === 'true' && checkPeriod()) {
      setInitialUltimatePromoData(ultimatePromo);
      localStorage.setItem(ULTIMATE_MODAL_KEY, `${Date.now()}`);
      openModal(ultimatePromo);
    }
    localStorage.removeItem(ULTIMATE_MODAL_SHOW_FLAG);
  }, [cart?.order, checkPeriod, history, isLoggedIn, openModal, ultimatePromo]);

  const wrapProceedToCheckout = useCallback(
    async (fn: () => Promise<any>) => {
      if (isLoggedIn && ultimatePromo && checkPeriod()) {
        openModal(ultimatePromo);
      } else {
        if (!isLoggedIn) {
          localStorage.setItem(ULTIMATE_MODAL_SHOW_FLAG, 'true');
        }
        void fn();
      }
    },
    [checkPeriod, isLoggedIn, openModal, ultimatePromo],
  );
  const minCartAmountNeeded = isAppliedUltimatePromo
    ? currentUltimatePromo?.minCartAmountNeeded
    : cart?.order?.upsellModalPromoRecommendations?.find(({ id }) => initialUltimatePromoData?.id === id)
        ?.minCartAmountNeeded;
  const onClose = useCallback(() => setInitialUltimatePromoData(null), []);
  const props = useMemo(
    () => ({
      ...currentUltimatePromo,
      minCartAmountNeeded,
      cartPending,
      isApplied: isAppliedUltimatePromo,
      visible: !!initialUltimatePromoData,
      onClose,
      variantIds,
      total: price.total,
      savings: price.totalBeforePromo - price.total,
    }),
    [
      cartPending,
      currentUltimatePromo,
      initialUltimatePromoData,
      isAppliedUltimatePromo,
      minCartAmountNeeded,
      onClose,
      price.total,
      price.totalBeforePromo,
      variantIds,
    ],
  );

  return {
    wrapProceedToCheckout,
    props,
  };
};

export { useUltimatePromo };
