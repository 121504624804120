/* eslint-disable prettier/prettier */
import { SelectOption } from '@swe/shared/ui-kit/components/form/select';

import GetCaregiverPatientsList, { CaregiverPatient } from 'endpoints/cart/get-caregiver-patients-list';

type CaregiverPatientType = {
    isCaregiverEnabled?: boolean;
    currentUser: Partial<CaregiverPatient>;
}

type SelectCaregiverOption<VT = string> = SelectOption<VT> & {
    medicalId: string | null;
};

const useCaregiverPatientsList = ({ isCaregiverEnabled, currentUser }: CaregiverPatientType) => {
    const requestParam = isCaregiverEnabled ? undefined : null;
    const { data, error, isLoading, isValidating, mutate } = GetCaregiverPatientsList.useRequest(requestParam);
    const mapToSelectOptions = (patients?: CaregiverPatient[]): SelectCaregiverOption<string>[] | undefined => {
        return patients ? [({ id: 0, name: `Me (${currentUser.name})` } as CaregiverPatient), ...patients].map(({ id, medicalId, name }) => ({
            value: id.toString(),
            label: name,
            medicalId: medicalId || '',
        })) : undefined;
    };
    const caregiverPatientsSelectOptions = mapToSelectOptions(data?.patients);

    return {
        caregiverPatientsList: data?.patients,
        caregiverPatientsSelectOptions,
        error,
        isLoading,
        isValidating,
        mutate,
    };
};

export { useCaregiverPatientsList };
